<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate"
        >新增</el-button
      >
      <el-select
        v-model="listQuery.college_id"
        placeholder="请选择院系"
        style="width: 150px"
        class="filter-item"
        @change="
          getGradeList(),
            (listQuery.grade_id = null),
            (grade = []),
            (listQuery.major_id = null),
            (majorList = []),
            (listQuery.class_id = null),
            (classes = [])
        "
      >
        <el-option
          v-for="(v, i) in colleges"
          :key="i"
          :label="v.name"
          :value="v.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.grade_id"
        placeholder="请选择年级"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="
          getmajorList(listQuery.college_id),
            (listQuery.major_id = null),
            (majorList = []),
            (listQuery.class_id = null),
            (classes = [])
        "
      >
        <el-option
          v-for="item in grades"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.major_id"
        placeholder="请选择专业"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
        @change="getClassList(), (listQuery.class_id = null), (classes = [])"
      >
        <el-option
          v-for="(v, i) in majorList"
          :key="i"
          :label="v.name"
          :value="v.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="listQuery.class_id"
        placeholder="请选择班级"
        style="width: 150px; margin-left: 10px"
        class="filter-item"
      >
        <el-option
          v-for="item in classes"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-input
        v-model="listQuery.name"
        placeholder="请输入学生姓名"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-date-picker
        class="filter-item"
        v-model="entrance_at"
        type="month"
        value-format="yyyy-MM"
        placeholder="选择入学时间"
      >
      </el-date-picker>
      <el-select
        v-model="listQuery.is_normal"
        placeholder="状态"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="正常" :value="1" />
        <el-option label="冻结" :value="0" />
      </el-select>
      <el-button class="filter-item" type="primary" @click="handleFilter"
        >搜索</el-button
      >
    </div>
    <div class="filter-container">
      <!-- <el-select
        v-model="listQuery.semester"
        placeholder="请选择学期"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="第一学期" :value="1" />
        <el-option label="第二学期" :value="2" />
      </el-select>
      <el-select
        v-model="listQuery.sex"
        placeholder="请选择性别"
        clearable
        style="width: 150px"
        class="filter-item"
      >
        <el-option label="男" :value="1" />
        <el-option label="女" :value="0" />
      </el-select> -->
      <el-button class="filter-item" type="primary" @click="download">
        导出学生模板
      </el-button>
      <el-upload
        class="upload-demo"
        style="margin-left: 10px"
        accept=".xlsx,.xls"
        action=""
        :show-file-list="false"
        :auto-upload="false"
        :on-change="handleImportOrders"
      >
        <el-button class="filter-item" type="warning" icon="el-icon-upload">
          导入学生
        </el-button>
      </el-upload>
      <download-excel
        :fields="student_fields"
        :fetch="handleExport"
        type="xls"
        :name="'学生名单.xls'"
        worksheet="学生名单"
        header="学生名单"
      >
        <el-button class="filter-item" style="margin-left: 10px" type="success">
          下载学生名单
        </el-button>
      </download-excel>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @sort-change="changeSort"
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="学生姓名" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.name }} </template>
      </el-table-column>
      <el-table-column label="性别" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.sex == 1">男</el-tag>
          <el-tag type="success" v-else-if="scope.row.sex == 2">女</el-tag>
          <el-tag type="info" v-else>未设置</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="出生年月" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.birth_info }} </template>
      </el-table-column>
      <el-table-column label="学号" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.account }}</template>
      </el-table-column>
      <el-table-column label="院系" min-width="110" align="center" sortable>
        <template slot-scope="scope">{{ scope.row.college.name }}</template>
      </el-table-column>
      <el-table-column label="专业" min-width="110" align="center">
        <template slot-scope="scope">{{
          scope.row.new_class_student
            ? scope.row.new_class_student.classes.major.name
            : ""
        }}</template>
      </el-table-column>
      <el-table-column label="年级" min-width="110" align="center">
        <template slot-scope="scope">{{
          scope.row.new_class_student
            ? scope.row.new_class_student.classes.grade.name
            : ""
        }}</template>
      </el-table-column>
      <el-table-column label="班级" min-width="110" align="center">
        <template slot-scope="scope">{{
          scope.row.new_class_student
            ? scope.row.new_class_student.classes.name
            : ""
        }}</template>
      </el-table-column>
      <el-table-column label="学年" min-width="110" align="center">
        <template slot-scope="scope">{{
          scope.row.new_class_student
            ? scope.row.new_class_student.classes.school_year +
              "-" +
              (scope.row.new_class_student.classes.school_year - 0 + 1)
            : ""
        }}</template>
      </el-table-column>
      <el-table-column label="联系电话" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.phone }} </template>
      </el-table-column>
      <el-table-column label="入学时间" min-width="110" align="center">
        <template slot-scope="scope">{{ scope.row.entrance_at }} </template>
      </el-table-column>
      <el-table-column label="状态" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_normal">正常</el-tag>
          <el-tag type="danger" v-else>冻结</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleUpdate(scope.row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="handleStatus(scope.row.id, '冻结')"
            v-if="scope.row.is_normal"
            >冻结</el-button
          >
          <el-button
            size="mini"
            type="success"
            @click="handleStatus(scope.row.id, '解冻')"
            v-else
            >解冻</el-button
          >
          <!-- <el-button
            size="mini"
            type="primary"
            @click="initPassword(scope.row.id, '初始化')"
            >初始化密码</el-button
          > -->
          <el-button
            size="mini"
            type="primary"
            @click="handleSchedule(scope.row)"
            >查看课表</el-button
          >
          <el-button
            size="mini"
            type="success"
            @click="handleUpdate(scope.row, 1)"
          >
            查看家长信息
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="150px">
        <template v-if="!isparent">
          <el-form-item label="入学时间" prop="entrance_at">
            <el-date-picker
              class="filter-item"
              v-model="form.entrance_at"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择入学时间"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="请选择院系" prop="college_id">
            <el-select
              v-model="form.college_id"
              placeholder="请选择院系"
              style="width: 100%"
            >
              <el-option
                v-for="item in colleges"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学生名称" prop="name">
            <el-input
              type="text"
              v-model="form.name"
              clearable
              placeholder="请输入学生名称"
            />
          </el-form-item>
          <el-form-item label="出生年月" prop="birth_info">
            <el-date-picker
              class="filter-item"
              v-model="form.birth_info"
              value-format="yyyy-MM"
              default-value="2000"
              type="month"
              placeholder="选择出生年月"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="学生性别" prop="sex">
            <el-select v-model="form.sex" placeholder="请选择学生性别">
              <el-option label="男" :value="1"> </el-option>
              <el-option label="女" :value="2"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学生学号" prop="account">
            <el-input
              type="text"
              v-model="form.account"
              clearable
              placeholder="请输入学生学号"
            />
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input
              type="text"
              v-model="form.phone"
              clearable
              placeholder="请输入联系电话"
            />
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item label="家长姓名" prop="parent_name">
            <el-input
              type="text"
              v-model="form.parent_name"
              clearable
              placeholder="请输入学生学号"
            />
          </el-form-item>
          <el-form-item label="家长联系电话" prop="parent_phone">
            <el-input
              type="text"
              v-model="form.parent_phone"
              clearable
              placeholder="请输入联系电话"
            />
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="导入数据预览"
      :visible.sync="importDataDialogVisible"
      center
      width="60%"
      :close-on-click-modal="false"
    >
      <div style="height: 500px" class="scrollbar">
        <el-scrollbar style="height: 500px">
          <el-table :data="yulanData" style="width: 1100px">
            <el-table-column label="序号" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.index || "" }}
              </template>
            </el-table-column>
            <el-table-column label="院系" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.college_name || "" }}
              </template>
            </el-table-column>
            <el-table-column label="学生名称" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.name || "" }}
              </template>
            </el-table-column>
            <el-table-column label="性别" min-width="60" align="center">
              <template slot-scope="scope">
                {{
                  scope.row.sex == 1
                    ? "男"
                    : scope.row.sex == 2
                    ? "女"
                    : "未设置"
                }}
              </template>
            </el-table-column>
            <el-table-column label="出生年月" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.birth_info || "" }}
              </template>
            </el-table-column>
            <el-table-column label="入学时间" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.entrance_at || "" }}
              </template>
            </el-table-column>
            <el-table-column label="学生学号" min-width="60" align="center">
              <template slot-scope="scope">
                {{ scope.row.account }}
              </template>
            </el-table-column>
            <el-table-column label="学生联系电话" min-width="80" align="center">
              <template slot-scope="scope">
                {{ scope.row.phone }}
              </template>
            </el-table-column>
            <el-table-column label="家长姓名" min-width="80" align="center">
              <template slot-scope="scope">
                {{ scope.row.parent_name }}
              </template>
            </el-table-column>
            <el-table-column label="家长联系电话" min-width="80" align="center">
              <template slot-scope="scope">
                {{ scope.row.parent_phone }}
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="importDataDialogVisible = false">取消</el-button>
        <el-button @click="importSaveData" type="primary" :loading="btnLoading"
          >确认导入</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import XLSX from "xlsx";
import request from "@/utils/request";
import downloadExcel from "vue-json-excel";
import { excel2Json } from "@/utils/excel";
import moment from "moment";
export default {
  components: { downloadExcel },
  data() {
    return {
      list: [],
      total: null,
      listLoading: false,
      entrance_at: null,
      listQuery: {
        page: 1,
        limit: 10,
        name: "",
        is_normal: "",
        college_name_sort: "",
        grade_id: "",
        class_id: "",
      },
      textMap: { update: "修改", create: "新增" },
      dialogFormVisible: false,
      importDataDialogVisible: false,
      dialogStatus: "",
      btnLoading: false,
      colleges: [],
      form: {
        id: "",
        entrance_at: "",
        college_id: "",
        name: "",
        account: "",
        phone: "",
        sex: "",
        birth_info: "",
        parent_name: "",
        parent_phone: "",
      },
      rules: {
        entrance_at: [
          { required: true, message: "入学时间不能为空", trigger: "change" },
        ],
        college_id: [
          { required: true, message: "院系不能为空", trigger: "change" },
        ],
        name: [
          { required: true, message: "学生姓名不能为空", trigger: "change" },
        ],
        birth_info: [
          { required: true, message: "出生年月不能为空", trigger: "change" },
        ],
        sex: [
          { required: true, message: "学生性别不能为空", trigger: "change" },
        ],
        account: [
          {
            required: true,
            message: "学生学号不能为空",
            trigger: "change",
          },
        ],
        phone: [
          { required: true, message: "学生电话不能为空", trigger: "change" },
        ],
        parent_name: [
          { required: true, message: "家长姓名不能为空", trigger: "change" },
        ],
        parent_phone: [
          {
            required: true,
            message: "家长联系电话不能为空",
            trigger: "change",
          },
        ],
      },
      college_ids: [], //院系
      grades: [],
      majorList: [],
      classes: [],
      json_data: [],
      tableData: [],
      yulanData: [],
      //导出
      json_fields: {
        序号: "student.name",
        "入学时间（格式：xxxx-xx-xx）": "student.name",
        "学院/系别（请认证核对管理系统相关学院）": "student.name",
        学生名称: "student.name",
        性别: "student.name",
        "出生年月(格式：xxxx-xx)": "student.name",
        学生学号: "student.name",
        学生联系电话: "student.name",
      },
      //导出学生花名册
      student_fields: {
        序号: "index",
        "入学时间(格式:xxxx/xx/xx)": "entrance_at",
        "院系（请认证核对管理系统相关院系）": "college_name",
        "专业":'new_class_student.classes.major.name',
        年级:'new_class_student.classes.grade.name',
        班级:'new_class_student.name',
        学年:'new_class_student.school_year',
        学生名称: "name",
        性别: {
          field: "sex",
          //自定义回调函数
          callback: (value) => {
            let val = "";
            if (value == 1) {
              val = "男";
            } else {
              val = "女";
            }
            return "<p style=\"mso-number-format:'\\@';\">" + val + "</p>";
          },
        },
        出生年月: {
          field: "birth_info",
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        学生学号: {
          field: "account",
          //自定义回调函数
          callback: (value) => {
            return "<p style=\"mso-number-format:'\\@';\">" + value + "</p>";
          },
        },
        学生联系电话: "phone",
        家长姓名: "parent_name",
        家长联系电话: "parent_phone",
      },
      isparent: false,
    };
  },
  watch: {
    entrance_at(newVlue, old) {
      if (!newVlue) {
        this.listQuery.year = "";
        this.listQuery.month = "";
      }
    },
  },
  created() {
    this.getCollegeList();
    this.getList();
  },
  methods: {
    //下载模板
    download() {
      window.open(
        "https://eclass-img.jcqjmk.com/学生信息导入模板.xlsx?attname=学生信息导入模板.xlsx",
        "_parent"
      );
    },
    handleExport() {
      this.listQuery.limit = 99999999999999;
      return request({
        url: "/api/schoolend/student/list",
        method: "get",
        params: this.listQuery,
      })
        .then((result) => {
          console.log(result.data.data);
          var json_data = result.data.data;
          json_data.forEach((element, index) => {
            element.index = index + 1;
          });
          // console.log(json_data[0].classes.college.school.name);
          this.listQuery.limit = 10;
          // this.header =
          //   json_data[0].classes.college.school.name +
          //   json_data[0].classes.college.name +
          //   json_data[0].classes.major.name +
          //   json_data[0].classes.grade.name +
          //   json_data[0].classes.name;
          return json_data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    getCollegeList() {
      request({
        url: "/api/schoolend/school/collegeList",
        method: "get",
        params: {
          limit: 999999999999999,
          is_setting: 1,
        },
      }).then((response) => {
        this.colleges = response.data.data;
      });
    },
    getmajorList() {
      request({
        url: "/api/schoolend/school/majorList",
        method: "get",
        params: {
          college_id: this.listQuery.college_id,
          grade_id: this.listQuery.grade_id,
          limit: 999999999,
        },
      }).then((response) => {
        this.majorList = response.data.data;
      });
    },
    getGradeList() {
      request({
        url: "/api/schoolend/school/gradeList",
        method: "get",
        params: {
          limit: 999999999999999,
          school_year: this.listQuery.school_year,
          college_id: this.listQuery.college_id,
          major_id: this.listQuery.major_id,
        },
      }).then((response) => {
        this.grades = response.data.data;
      });
    },
    getClassList() {
      this.classes = [];
      this.listQuery.class_id = null;
      request({
        url: "/api/schoolend/school/classList",
        method: "get",
        params: {
          limit: 9999999999999,
          grade_id: this.listQuery.grade_id,
          college_id: this.listQuery.college_id,
          major_id: this.listQuery.major_id,
          school_year: this.listQuery.school_year,
        },
      }).then((response) => {
        this.classes = response.data.data;
      });
    },
    //排序
    changeSort(e) {
      this.listQuery.college_name_sort = "";
      if (e.order == null) {
        this.getList();
        return;
      }
      switch (e.column.label) {
        case "院系":
          if (e.order == "ascending") {
            this.listQuery.college_name_sort = 0;
          } else {
            this.listQuery.college_name_sort = 1;
          }
          break;
        default:
          break;
      }
      this.getList();
    },
    handleFilter() {
      this.listQuery.page = 1;
      if (this.entrance_at) {
        this.listQuery.year = this.entrance_at.split("-")[0];
        this.listQuery.month = this.entrance_at.split("-")[1];
      }
      this.getList();
    },
    //导入
    formatDate(numb, format) {
      const time = new Date((numb - 1) * 24 * 3600000 + 1);
      time.setYear(time.getFullYear() - 70);
      const year = time.getFullYear() + "";
      const month = time.getMonth() + 1 + "";
      const date = time.getDate() - 1 + "";
      if (format && format.length === 1) {
        return (
          year +
          format +
          (month < 10 ? "0" + month : month) +
          format +
          (date < 10 ? "0" + date : date)
        );
      }
      return (
        year +
        (month < 10 ? "0" + month : month) +
        (date < 10 ? "0" + date : date)
      );
    },
    handleImportOrders(file) {
      console.log(1111);
      this.yulanData = [];
      file = file.raw;
      if (
        !(file.name.split(".")[1] == "xlsx" || file.name.split(".")[1] == "xls")
      ) {
        this.$message.error("上传文件格式不正确，请检查选择的文件");
        return;
      }
      excel2Json(file, 1).then((excelData) => {
        console.log(excelData);
        // 处理业务
        if (excelData.length <= 0) {
          this.$message.error("数据不能为空");
          return;
        }
        excelData.forEach((element, index) => {
          console.log(element, "--------", index);
          if (index > 0) {
            this.yulanData.push({
              index: index,
              entrance_at:
                moment(
                  element["__EMPTY"] * 24 * 60 * 60 * 1000 +
                    Date.parse("1899-12-30")
                ).format("YYYY-MM-DD") || "",
              college_name: element["__EMPTY_1"] || "",
              name: element["__EMPTY_2"] || "",
              sex: element["__EMPTY_3"] == "男" ? 1 : 2,
              birth_info:
                element["__EMPTY_4"] > 0
                  ? moment(
                      element["__EMPTY_4"] * 24 * 60 * 60 * 1000 +
                        Date.parse("1899-12-30")
                    ).format("YYYY-MM-DD") || ""
                  : element["__EMPTY_4"],
              account: element["__EMPTY_5"] + "" || "",
              phone: element["__EMPTY_6"] || "",
              parent_name: element["__EMPTY_7"] || "",
              parent_phone: element["__EMPTY_8"] || "",
            });
          }
        });
        this.importDataDialogVisible = true;
      });
      console.log(this.yulanData, "=============");
    },
    importSaveData() {
      for (let index = 0; index < this.yulanData.length; index++) {
        const item = this.yulanData[index];
        for (var key in item) {
          if (!item[key]) {
            this.$message({
              type: "error",
              message: "请完善数据!!",
            });
            return;
          }
        }
      }
      // return;
      request({
        url: "/api/schoolend/student/importStudent",
        method: "post",
        data: {
          students: this.yulanData,
          is_confirm: false,
        },
      })
        .then((response) => {
          console.log(response);
          this.btnLoading = false;
          this.importDataDialogVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
        .catch((err) => {
          this.btnLoading = false;
          this.$alert(err.msg, "提示", {
            confirmButtonText: "确定",
            callback: (action) => {
              request({
                url: "/api/schoolend/student/importStudent",
                method: "post",
                data: {
                  students: this.yulanData,
                  is_confirm: true,
                },
              }).then((res) => {
                this.btnLoading = false;
                this.importDataDialogVisible = false;
                this.getList();
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
              });
            },
          });
          // this.$forceUpdate(); //强制更新
          // this.errArr = err.msg;
          // console.log(this.btnLoading);
          // this.$message({
          //   type: "error",
          //   message: err.msg,
          // });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },

    handleSchedule(row) {
      this.$router.push(
        `/school/check_curriculum?class_id=${row.new_class_student.class_id}&student_id=${row.new_class_student.student_id}`
      );
    },
    getCollegeList() {
      request({
        url: "/api/schoolend/school/collegeList",
        method: "get",
        params: {
          limit: 999999999999999,
          is_on: 1,
        },
      }).then((response) => {
        this.colleges = response.data.data;
      });
    },
    getList() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/student/list",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    // handleFilter() {
    //   this.listQuery.page = 1;
    //   this.getList();
    // },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
      };
    },
    handleCreate() {
      this.resetForm();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    handleUpdate(row, key) {
      if (key) {
        this.isparent = true;
      } else {
        this.isparent = false;
      }
      this.$forceUpdate(); //强制更新
      this.resetForm();
      this.form = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    saveData() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/schoolend/student/store",
            method: "post",
            data: this.form,
          })
            .then((response) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    //上下架
    handleStatus(val, text) {
      this.$confirm("此操作将" + text + "该院系, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/schoolend/student/changeNormal",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
    //上下架
    initPassword(val, text) {
      this.$confirm("此操作将" + text + "该学生密码, 是否继续?", "提示", {
        type: "warning",
      })
        .then(() => {
          request({
            url: "/api/schoolend/student/initPassword",
            method: "post",
            data: { id: val },
          }).then(() => {
            this.getList();
            this.$message({
              type: "success",
              message: "操作成功",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.filter-container {
  display: flex;
}
</style>
